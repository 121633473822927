import { upgradeProperty } from '../../utils/upgradeProperty/upgradeProperty.js';
import { cxModuleLoader, render, fetchCampaignData } from './';
import AnalyticsService from './analytics';
import ContextService from '../context';
import DisclaimerService from '../disclaimer';
import template from './template.js';

let devEnv = '';
try {
  devEnv = process.env.NODE_ENV === 'development';
} catch {}

function camelCase(str) {
  return str.replace(/^.|-./g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.substr(1).toUpperCase();
  });
}

const defaultAttributes = ['slot-id', 'override-url'];

const WidgetElement = ({
  attributes = [],
  contract,
  customCss = '',
  cxClient,
  disclaimer = {},
  fetchCampaign = attr => fetchCampaignData(attr.slotId, attr.overrideUrl),
  loader = null,
  name,
  version
}) => {
  const wEvent = (eventName, detail) =>
    new CustomEvent(`${name}:${eventName}`, {
      bubbles: true,
      composed: true,
      detail
    });

  return class extends HTMLElement {
    constructor() {
      super();

      if (!this.shadowRoot) {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(
          template(customCss).content.cloneNode(true)
        );
      }

      if (version) this._version = version;
    }

    async connectedCallback() {
      [...defaultAttributes, ...attributes].forEach(key => {
        Object.defineProperty(this, camelCase(key), {
          get() {
            return this.getAttribute(key);
          },
          set(value) {
            this.setAttribute(key, value);
          }
        });

        upgradeProperty(this, key);
      });

      this.bootstrap();
    }

    async bootstrap() {
      const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');
      const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');
      loadingSlot.hidden = false;

      try {
        const target = this.shadowRoot.getElementById('root');

        const [{ Component }, campaignData] = await Promise.all([
          cxModuleLoader(cxClient, this.shadowRoot),
          fetchCampaign(this)
        ]);

        if (!Component || !target) {
          return;
        }

        const analytics = AnalyticsService(campaignData);

        ContextService({
          ...campaignData,
          slotId: this.slotId,
          overrideUrl: this.overrideUrl
        });

        DisclaimerService(disclaimer(campaignData), this);

        const props = await contract(
          {
            ...campaignData,
            analytics
          },
          this
        );

        if (props.theme) {
          this.setAttribute('theme', props.theme);
        }

        render(target, Component, props, loader);

        this.dispatchEvent(wEvent('load-success', campaignData));
      } catch (error) {
        errorSlot.hidden = false;
        if (devEnv) console.error(error);

        this.dispatchEvent(wEvent('load-error', { error }));
      } finally {
        loadingSlot.hidden = true;

        this.dispatchEvent(wEvent('load'));
      }
    }
  };
};

export function widgetBootstrap(props) {
  if (!window.customElements || window.customElements.get(props.name)) return;

  const widget = WidgetElement(props);
  window.customElements.define(props.name, widget);
}
