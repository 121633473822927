import { Analytics } from '../analytics.js';
import { isAnalyticsDisabled } from '../../utils/isAnalyticsDisabled/isAnalyticsDisabled.js';

export default function AnalyticsService({
  trackingRate,
  useDefaultDataLayer,
  gtmId
}) {
  let analytics = false;

  const disableAnalytics = isAnalyticsDisabled(trackingRate);
  if (!disableAnalytics) {
    analytics = new Analytics(
      gtmId,
      useDefaultDataLayer ? 'dataLayer' : undefined
    );

    if (!analytics.getDataLayer()) {
      analytics.integrateAnalytics();
    }
  }

  return analytics;
}
