import { html } from '../../utils/lit/html.js';
import OCLogoUSDark from '../../shared/assets/icons/oddschecker-logo-us-dark.png';

const styles = html`<style>
  .footer {
    user-select: none;
    -webkit-user-select: none;
    background: var(--disclaimer-background, #ccc);
    border: var(--disclaimer-border, 0);
    border-radius: var(--disclaimer-border-radius, 0);
    color: var(--disclaimer-color, #000);
    padding: var(--disclaimer-padding, 10px);
    font-family: var(--disclaimer-font-family, inherit);
    font-size: var(--disclaimer-font-size, 13px);
  }

  .footer .notice {
    user-select: text;
    -webkit-user-select: text;
  }

  .footer .notice:not(:empty) + .icons {
    margin-top: 16px;
  }

  .footer .icons {
    display: flex;
    justify-content: space-between;
  }

  .footer .icons > :not(.poweredby) {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    gap: 8px;
  }

  .footer .poweredby {
    display: flex;
    align-items: end;
    gap: 10px;
  }

  .footer .icons img {
    height: 20px;
  }

  .footer .poweredby:only-child {
    margin-left: auto;
  }
</style> `;

export default function DisclaimerTemplate({ notice, icons, poweredby }) {
  return html` ${styles}
    <footer class="footer">
      ${notice ? html`<div class="notice">${notice}</div>` : ''}

      <div class="icons">
        ${icons ? html`<div>${icons}</div>` : ''}
        ${poweredby
          ? `<div class="poweredby">
          <span>Powered by</span>
          <img src="${OCLogoUSDark}" alt="Oddschecker Logo" height="20" />
        </div>`
          : ''}
      </div>
    </footer>`;
}
