import { html } from '../../utils/lit/html.js';

const template = (css = '') => {
  const e = document.createElement('template');
  e.innerHTML = html`
    <style>
      ${css}
    </style>
    <div id="root"></div>
    <slot name="loading" hidden> Loading... </slot>
    <slot name="error" hidden></slot>
    <slot name="disclaimer" hidden></slot>
  `;
  return e;
};

export default template;
